import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout-blog.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`PCMH Restore Health emphasizes the importance of staying abreast with the latest happenings in the medical field. We also believe in contributing to the field and doing our bit to help improve patient care.`}</p>
    <p>{`Dr. Ashoojit, our clinical director, participated in TS-AIDSCON 2018, the Telangana State AIDS Conference, which focuses on raising global awareness of STDs and AIDS and fighting the stigma surrounding these diseases. The conference was organised on the 15th of April. She presented her take on managing HIV positive patients who were non-adherent to therapy and the factors influencing adherence. This was based on a scientific study carried out by PCMH RH in association with Dr. Milind Bhrushundi - HIV specialist at Lata Mangeshkar Hospital, Nagpur.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "750px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c2c504b08e2830ef60f1f297ac39e434/08b4d/ts-aidscon-2018-1.webp",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/webp;base64,UklGRowAAABXRUJQVlA4IIAAAABQBACdASoUAA8APtFWo0uoJKMhsAgBABoJZAB34FP8B4mYAsKMpWtZorGAAP6uw2GzwYhvDoBFluf9qUDnk5JT4JnmaIUHmds5U8xMztXBIfXsc5WAswSpwXMTLcwQhCysIETEDf+qoglgGW+7bQFUdsjW0Qqz1GgIkBsAfgAAAA==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ts aidscon 2018 1",
            "title": "ts aidscon 2018 1",
            "src": "/static/c2c504b08e2830ef60f1f297ac39e434/08b4d/ts-aidscon-2018-1.webp",
            "srcSet": ["/static/c2c504b08e2830ef60f1f297ac39e434/c85cb/ts-aidscon-2018-1.webp 300w", "/static/c2c504b08e2830ef60f1f297ac39e434/e88ff/ts-aidscon-2018-1.webp 600w", "/static/c2c504b08e2830ef60f1f297ac39e434/08b4d/ts-aidscon-2018-1.webp 750w"],
            "sizes": "(max-width: 750px) 100vw, 750px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "750px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d88e234b1160eff7ddefee4df28ea279/08b4d/ts-aidscon-2018-2.webp",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/webp;base64,UklGRo4AAABXRUJQVlA4IIIAAADwAwCdASoUAA8APtFUo0uoJKMhsAgBABoJagCdACFQyDQvGfWlo49gAP6utTKjV6gJMQL0BYxf0dpAFFJC5PTuuN87QEFfgB81yehgupYwIx89CV3PL2QSiyyMDi59xl9baeRtrDvLSbFoiei3Mj/IiY9dFe4/c8XlNAdqpJuHCAAA')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ts aidscon 2018 2",
            "title": "ts aidscon 2018 2",
            "src": "/static/d88e234b1160eff7ddefee4df28ea279/08b4d/ts-aidscon-2018-2.webp",
            "srcSet": ["/static/d88e234b1160eff7ddefee4df28ea279/c85cb/ts-aidscon-2018-2.webp 300w", "/static/d88e234b1160eff7ddefee4df28ea279/e88ff/ts-aidscon-2018-2.webp 600w", "/static/d88e234b1160eff7ddefee4df28ea279/08b4d/ts-aidscon-2018-2.webp 750w"],
            "sizes": "(max-width: 750px) 100vw, 750px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "750px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d7e4d984722dfbf71d631e5a981b75b6/08b4d/ts-aidscon-2018-3.webp",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/webp;base64,UklGRs4AAABXRUJQVlA4IMIAAACQBACdASoUAA8APtFUo0uoJKMhsAgBABoJbACdMoMYLIAKfgTV5HlyvIhomzgA/tzwul8fClyFrKgr4j3PqJKiYcJFlBAAgom28fDAdDXrnEh5thdGnpaBlDcXB15hv30UdfIUGZotlt5jaYRdrVNGBHeMORuUq1zEfnt7iXA2Wno6mOwBKceIVpR18AoUObSv71nZ3iLa6kjw5t47XsgVgZcG2YD2+zT7jjakDWNHeOKmfYN1Z2RF99x3c7AEcAAAAA==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ts aidscon 2018 3",
            "title": "ts aidscon 2018 3",
            "src": "/static/d7e4d984722dfbf71d631e5a981b75b6/08b4d/ts-aidscon-2018-3.webp",
            "srcSet": ["/static/d7e4d984722dfbf71d631e5a981b75b6/c85cb/ts-aidscon-2018-3.webp 300w", "/static/d7e4d984722dfbf71d631e5a981b75b6/e88ff/ts-aidscon-2018-3.webp 600w", "/static/d7e4d984722dfbf71d631e5a981b75b6/08b4d/ts-aidscon-2018-3.webp 750w"],
            "sizes": "(max-width: 750px) 100vw, 750px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "750px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d3864fcc76b8881a50ac991d32fe3244/08b4d/ts-aidscon-2018-4.webp",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/webp;base64,UklGRroAAABXRUJQVlA4IK4AAACwBACdASoUAA8APtFUo0uoJKMhsAgBABoJbACdMoRwEGADlSlBbxtAAGNkoTCAAPuRprEliHMLUlz71Gk7dT0gbv7B54e1johrAXrZykk49+epVuJTFCkiuZk/YDgsGSBAyjLytzV8m4uSYNNNyegEArbBLEpsx4wQgum533XpB36W1EzHOpAqPdaLGoXdMj9fFB/yn0IRSSN3SLdC+xesh3XR6VGy9tAeR3PQAAA=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ts aidscon 2018 4",
            "title": "ts aidscon 2018 4",
            "src": "/static/d3864fcc76b8881a50ac991d32fe3244/08b4d/ts-aidscon-2018-4.webp",
            "srcSet": ["/static/d3864fcc76b8881a50ac991d32fe3244/c85cb/ts-aidscon-2018-4.webp 300w", "/static/d3864fcc76b8881a50ac991d32fe3244/e88ff/ts-aidscon-2018-4.webp 600w", "/static/d3864fcc76b8881a50ac991d32fe3244/08b4d/ts-aidscon-2018-4.webp 750w"],
            "sizes": "(max-width: 750px) 100vw, 750px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The study strongly corroborated our experience with patients: adherence to treatment is influenced by multiple factors such as stigma associated with the disease, counselling before starting medication, counselling during treatment, social support, financial capabilities etc. As physicians we believe that it is important to take all these factors into consideration while handling patients, particularly those with chronic illnesses.`}</p>
    <p>{`Our patient centered health care does just that! By putting the patient at the center of our discussion, we take the time to understand not just the patient's but also his/her family’s concerns. We answer their queries and help find solutions that work best for them. This, evidently, leads to the best possible outcome.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      